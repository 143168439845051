html {
	height: 100%;
	padding: 0;
	scroll-behavior: smooth;
}

body {
	height: 100%;
	margin: 0;
}
